<template>
  <div class="approver">
    <b-avatar
      variant="info"
      size="30px"
      v-b-tooltip.hover
      :title="approver.name"
      :text="getNameInitials(approver.name)"
      :src="approver.photo"
    />
    <div
      v-if="isApproved(approver.status) || isDeclined(approver.status) || isCanceled(approver.status)"
      class="approver__icon"
      :class="{
        'approver__icon_checked': isApproved(approver.status),
        'approver__icon_declined': isDeclined(approver.status),
        'approver__icon_canceled': isCanceled(approver.status),
      }"
    >
      <i v-if="isApproved(approver.status)" class="bx bx-check" />
      <i v-if="isDeclined(approver.status)" class="bx bx-x" />
      <i v-if="isCanceled(approver.status)" class='bx bx-minus-circle'></i>

    </div>
  </div>
</template>

<script>
import { getNameInitials } from '@/utils/user';

export default {
  props: {
    approver: Object
  },
  methods: {
    getNameInitials,
    isApproved(status) {
      return status === 'approved' || status === 'done' || status === 'paid';
    },
    isDeclined(status) {
      return status === 'declined';
    },
    isCanceled(status) {
      return status === 'canceled'
    }
  }
}
</script>

<style lang="scss" scoped>
.approver {
  width: 30px;
  height: 30px;
  position: relative;

  &__icon {
    top: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;

    &_checked {
      background: #28A745;
    }

    &_declined {
      background: #DC3545;
    }
    &_canceled {
      background: #6c757d;
    }

    i {
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
