<template>
  <div
    class="purchase-status-badge"
    :class="`purchase-status-badge_${color}`"
  >
    {{ title }}
  </div>
</template>

<script>

const STATUS_COLORS = {
  done: 'green',
  declined: 'red',
  pending: 'yellow',
  approved: 'green',
  waiting_for_approval: 'blue',
  paid: 'blue',
  canceled: 'gray'
}

export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    color() {
      return STATUS_COLORS[this.status]
    },
    title() {
      const firstLetter = this.status[0];
      return (firstLetter.toUpperCase() + this.status.slice(1)).replaceAll('_', ' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.purchase-status-badge {
  color: #fff;
  font-size: 10px;
  padding: 0px 4px;
  font-weight: 500;
  line-height: 15px;
  font-style: normal;
  border-radius: 4px;
  display: inline-block;

  &_green {
    background: #28A745;
  }
  &_red {
    background: #DC3545;
  }
  &_yellow {
    background: #FFC107;
  }
  &_blue {
    background: #17A2B8;
  }
  &_gray {
    background-color: #6c757d;
  }
}
</style>
